<template>
  <section id="plan-numbers">
    <b-card>
      <b-row>
        <b-col xl="6" md="6">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input v-model="searchValue" placeholder="Search" @keyup.enter="searchPlanNumbers" />
          </b-input-group>
        </b-col>
        <b-col xl="6" md="6" />
      </b-row>
    </b-card>
    <b-overlay :show="show" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
      <b-card no-body>
        <b-card-header>
          <h4 class="mb-0">
            Plan numbers
            <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-plan-numbers" />
          </h4>
          <b-popover target="popover-plan-numbers" triggers="hover" placement="bottom">
            <span>No data</span>
          </b-popover>
          <b-card-text class="font-medium-5 mb-0">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="btn-block"
              @click="addPlanNumbersClicked">
              Add plan numbers
            </b-button>
          </b-card-text>
        </b-card-header>
        <b-table striped hover responsive class="position-relative" :current-page="planNumbersTable.currentPage"
          :items="planNumbersTable.items" :fields="planNumbersTable.fields" :sort-by.sync="planNumbersTable.sortBy"
          :sort-desc.sync="planNumbersTable.sortDesc" :sort-direction="planNumbersTable.sortDirection"
          :filter="planNumbersTable.filter" :filter-included-fields="planNumbersTable.filterOn"
          @row-clicked="planNumbersRowClicked">
          <template #cell(delete)="data">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="flat" class="btn-icon"
              @click="openDeleteConfirm(data.item.id)">
              <feather-icon icon="Trash2Icon" />
            </b-button>
          </template>
        </b-table>
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- pagination -->
          <div>
            <b-pagination v-model="planNumbersTable.currentPage" :total-rows="planNumbersTable.totalRows" first-number
              last-number prev-class="prev-item" next-class="next-item" class="mb-0"
              @change="handlePlanNumbersPageChange">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-card>
      <b-modal ref="modal-plan-numbers-submit" cancel-variant="outline-secondary" ok-title="Submit" cancel-title="Close"
        centered :title="planNumbersID ? 'Update' : 'Add'" :no-close-on-backdrop="true" @ok="handlePlanNumbersSubmit"
        @hidden="resetPlanNumbersModal">
        <b-form @submit.prevent>
          <b-form-group label="Name" label-for="planNumbersName">
            <b-form-input id="planNumbersName" v-model="planNumbersName" />
          </b-form-group>
          <b-form-group label="Data" label-for="planNumbersData">
            <b-form-textarea id="planNumbersData" v-model="planNumbersData" rows="8" />
          </b-form-group>
        </b-form>
      </b-modal>
    </b-overlay>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BTable,
  BPagination,
  BCard,
  BCardBody,
  BCardHeader,
  BCardText,
  BPopover,
  BModal,
  BOverlay,
  BButton,
  BInputGroup,
  BInputGroupPrepend,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import axios from 'axios';

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
  components: {
    BRow,
    BCol,
    BTable,
    BPagination,
    BCard,
    BCardBody,
    BCardHeader,
    BCardText,
    BPopover,
    BModal,
    BOverlay,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      show: true,
      searchValue: '',
      planNumbersID: '',
      planNumbersName: '',
      planNumbersData: '',
      queryParams: {},
      planNumbersTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'created',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'name', label: 'name', sortable: true },
          {
            key: 'created',
            label: 'created at',
            sortable: true,
            formatter: value => {
              const new_date = new Date(value);
              if (value) {
                return new_date.toLocaleDateString('de-DE');
              }
              return '';
            },
          },
          { key: 'delete', label: '' },
        ],
        /* eslint-disable global-require */
        items: [],
      },
    }
  },
  async created() {
    try {
      await this.getPlanNumbers();
    } catch (error) {
      if (error.response.status === 401) {
        this.$router.push({ name: 'login' });
      }
    }
  },
  methods: {
    showToast(variant, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: text,
          icon: 'BellIcon',
          variant,
        },
      })
    },
    async makeRequest(url, params) {
      return axios.get(url, {
        headers: {
          Authorization: `JWT ${this.$store.state.jwt}`,
          'Content-Type': 'application/json',
        },
        params,
      });
    },
    async getPlanNumbers() {
      this.show = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/plannumbers/`, this.queryParams);
        this.planNumbersTable.items = response.data.results;
        this.planNumbersTable.totalRows = response.data.count * 2;
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.show = false;
      }
    },
    async searchPlanNumbers() {
      this.queryParams.page = 1;
      this.queryParams.search = '%' + this.searchValue.toLowerCase() + '%';
      await this.getPlanNumbers();
    },
    async handlePlanNumbersPageChange(value) {
      this.queryParams.page = value;
      await this.getPlanNumbers();
    },
    addPlanNumbersClicked() {
      this.$refs['modal-plan-numbers-submit'].show();
    },
    planNumbersRowClicked(row) {
      this.planNumbersID = row.id;
      this.planNumbersName = row.name;
      this.planNumbersData = row.data;
      this.$refs['modal-plan-numbers-submit'].show();
    },
    resetPlanNumbersModal() {
      this.planNumbersID = '';
      this.planNumbersName = '';
      this.planNumbersData = '';
    },
    async handlePlanNumbersSubmit() {
      const params = {
        name: this.planNumbersName,
        data: this.planNumbersData,
      };

      if (this.planNumbersID && this.planNumbersName !== '' && this.planNumbersData !== '') {
        await axios
          .put(`${process.env.VUE_APP_ROOT_API}/plannumbers/${this.planNumbersID}/`, params, {
            headers: {
              Authorization: `JWT ${this.$store.state.jwt}`,
              'Content-Type': 'application/json',
            },
          })
          .then(async response => {
            console.log(response);
            this.showToast('success', 'Plan numbers have been updated');
            await this.getPlanNumbers();
          })
          .catch(error => {
            if (error.response.status === 401) {
              this.$router.push({ name: 'login' });
            }
          })
      } else if (this.planNumbersName !== '' && this.planNumbersData !== '') {
        await axios
          .post(`${process.env.VUE_APP_ROOT_API}/plannumbers/`, params, {
            headers: {
              Authorization: `JWT ${this.$store.state.jwt}`,
              'Content-Type': 'application/json',
            },
          })
          .then(async response => {
            console.log(response);
            this.showToast('success', 'Plan numbers have been created');
            await this.getPlanNumbers();
          })
          .catch(error => {
            if (error.response.status === 401) {
              this.$router.push({ name: 'login' });
            }
          })
      } else { this.showToast('danger', 'Error!') }
    },
    openDeleteConfirm(id) {
      this.planNumbersID = id;
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          await axios
            .delete(`${process.env.VUE_APP_ROOT_API}/plannumbers/${this.planNumbersID}/`, {
              headers: {
                Authorization: `JWT ${this.$store.state.jwt}`,
                'Content-Type': 'application/json',
              },
            })
            .then(async response => {
              console.log(response);
              this.showToast('danger', 'Plan numbers have been deleted');
              await this.getPlanNumbers();
            })
            .catch(error => {
              if (error.response.status === 401) {
                this.$router.push({ name: 'login' });
              }
            })
        }
        this.planNumbersID = '';
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>
